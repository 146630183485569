import { initialState } from ".";
import { ADD_LOG, REMOVE_LOG, SET_LOGS } from "../actions/logger";
import { rid } from "../helpers";

export default function (state = initialState.logger, action) {
    switch(action.type) {

        // Adding log
        case ADD_LOG:
            return {
                ...state,
                logs: [ {...action.log, id: rid(), timestamp: Date.now()}, ...state.logs ]
            };
        // Removing log
        case REMOVE_LOG:
            return {
                ...state,
                logs: state.logs.filter(log => log.id)
            }

        // Replacing log array with a new one
        case SET_LOGS:
            return {
                ...state,
                logs: action.logs
            };
            
        default:
            return state
    }
}